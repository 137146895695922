/** @format */

.life-grid {
  margin: 0 auto;

  &__row {
    display: flex;
    justify-content: center;
    margin-top: 2px;
  }

  &__el {
    display: block;
    height: 1.3vw;
    width: 1.3vw;
    border: 1px solid var(--secondary);
    transition: background-color 0.5s ease;
    margin-right: 2px;
    font-size: 0.6em;
    // border-radius: 50%;

    @include m {
      height: 1vw;
      width: 1vw;
    }
  }

  &__el--active {
    background: var(--font);
    border-color: var(--font);
  }

  &__label {
    border: none;
    font-size: 0.9em;
    opacity: 0;

    &--show {
      opacity: 1;
    }
  }
}

.life-grid-wrapper {
  margin-top: 70px;
  margin-left: -20px;
  margin-right: -20px;
  display: grid;
  grid-template-columns: 12px 12px auto;
  grid-template-rows: 12px 12px auto;
  font-size: 0.5em;
  gap: 0px 0px;

  svg {
    width: 20px;
  }

  @include s {
    font-size: 1em;
    grid-template-columns: 35px 25px auto;
    grid-template-rows: 40px 25px auto;

    svg {
      color: --var(--secondary);
      width: 50px;
    }
  }

  &__weeks {
    font-family: $font-serif;

    > div {
      display: flex;
      gap: 10px;
    }
  }

  &__years {
    font-family: $font-serif;

    > div {
      display: flex;
      gap: 10px;
      transform: rotate(90deg);
    }
  }
}

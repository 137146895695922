/** @format */

.controls {
  label {
    display: block;
    margin-top: 1.6em;
    margin-bottom: 0.5em;
    font-weight: bold;
  }
}

.datepicker {
  width: 100%;

  input {
    background: transparent;
    color: var(--font);
  }

  svg {
    stroke: var(--font);
  }

  .react-calendar {
    background: $light;
    border: 1px solid;
    border-color: var(--font);
  }

  .react-calendar__month-view__weekdays {
    color: $dark;
  }

  .react-date-picker__wrapper {
    width: 100%;
    border: none;
  }
}

.datepicker__wrapper {
  border: 1px solid;
  border-color: var(--font);
  font-size: 1.2em;
  padding: 4px;

  max-width: 300px;
}

.yearpicker {
  select {
    background: transparent;
    color: var(--font);
    border: none;
    width: 100%;
    font-size: 1.2em;
    -webkit-appearance: none;
  }

  .yearpicker__wrapper {
    border: 1px solid;
    border-color: var(--font);
    font-size: 1.2em;
    padding: 4px;

    max-width: 300px;
  }
}

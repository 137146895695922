.theme-switcher {
  position: fixed;
  top:20px;
  right: 20px;
  
  button {
    background: transparent;
    border: none;
    color: $medium-dark;

    [data-theme="dark"]  & {
      color: $yellow;
    }
  }
}
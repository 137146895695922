/** @format */

.extra__info {
  > div {
    margin: 25px 0;
  }

  span {
    font-weight: bold;
  }
}

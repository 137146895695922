/** @format */

.main-app {
  display: flex;
  gap: 30px;
  flex-direction: column;

  &__left {
    width: 100%;
    margin-top: 30px;
    margin-right: 30px;

    @include s {
      width: 30%;
    }

    @include m {
      width: 40%;
      min-width: 300px;
    }
  }

  &__right {
    flex: 1 1 auto;
  }

  @include m {
    flex-direction: row;
    gap: 45px;
  }
}
